<template>
    <main class="h-100">
      <div class="container">
        <div style="width: 0;" class="d-none d-lg-block ">
            <h1 class="one-word-per-line">Dashboard</h1>
        </div>
        <h1 class="d-block d-lg-none mt-4">Dashboard</h1>



      <div class="overflow-y: auto">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum tempora inventore accusamus, iusto culpa sunt obcaecati hic voluptatum! Veritatis, sed rerum. Tempore voluptatem unde optio natus eligendi ipsam quasi iure.
      </div>
      </div>
    </main>
</template>
<script>

import { mapGetters } from 'vuex';
import { } from "../api";

export default {
    data() {
        return {
            email: "",
            selectedTemplate: "etsy",
            templates: [
                { thumbnail: "https://placekitten.com/400/1200", name: "Etsy Default", id: "etsy" },
                // { thumbnail: "https://placekitten.com/400/1200", name: "Etsy Default (6x4\")", id: "etsy64" },
            ],
            templateCustomizations: {
                showShippingAddress: false,
            }
        }
    },
  async mounted() {
    },
    computed: {
        ...mapGetters([]),
    },
    watch: {
    },
    methods: {
    }
}
</script>

<style>

</style>